import { css } from '@emotion/core';

export const fontFaces = css`
  @font-face {
    font-family: 'FbKaprizaBold';
    src: url('/fonts/FbKaprizaBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
  }
`;
