import { dimensions, fonts, colors, breakpoints } from "./variables";
import { css } from "@emotion/core";
import {
  fluidRange,
  fontSmoothing,
  absoluteFill,
  transitionDefault,
  respondFrom,
  respondTo
} from "~/styles";
import { keyframes } from "~/animations";
import { fontFaces } from "~/styles/fonts";

export const global = css`
  ${fontFaces};
  ${keyframes};

  html {
    box-sizing: border-box;
    scroll-behavior: smooth;
  }
  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
  html {
    font-size: ${dimensions.fontSize.regular}px;
    line-height: ${dimensions.lineHeight.regular};
  }
  body {
    width: 100%;
    min-width: 320px;
    overflow-x: hidden;
    overflow-y: scroll;
    font-family: ${fonts.default};
    font-size: ${dimensions.fontSize.small}px;
    font-weight: 300;
    color: ${colors.text};
    background-color: ${colors.white};
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    ${fontSmoothing};

    &.noscroll {
      overflow: hidden;
    }
  }
  a {
    text-decoration: underline;
    color: inherit;
    &:hover,
    &:focus {
      text-decoration: underline;
    }
  }
  img {
    max-width: 100%;
    max-height: 100%;
    object-fit: contain;
    position: relative;
    display: inline-block;
  }
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-top: 0;
    margin-bottom: 0.2em;
    font-weight: bold;
    line-height: ${dimensions.lineHeight.heading};
    text-rendering: optimizeLegibility;
    font-family: ${fonts.headers};
    overflow: hidden;
    /* text-overflow: ellipsis; */
    max-width: 100%;
    word-wrap: break-word;
    text-transform: uppercase;
  }
  h1 {
    ${fluidRange("fontSize", "35px", "38px")}
  }
  h2 {
    ${fluidRange("fontSize", "25px", "35px")}
  }
  h3 {
    ${fluidRange("fontSize", "20px", "30px")}
  }
  h4 {
    ${fluidRange("fontSize", "20px", "24px")}
  }

  h5,
  h6 {
    ${fluidRange("fontSize", "18px", "20px")}
  }
  p,
  .p {
    margin-top: 0;
    margin-bottom: 2rem;
    line-height: 1.5;
  }
  strong {
    font-weight: 700;
  }
  ul,
  ol,
  dl {
    margin-top: 0;
    margin-bottom: 1rem;
  }
  dt {
    font-weight: bold;
  }
  dd {
    margin-bottom: 0.5rem;
  }
  hr {
    position: relative;
    margin: 1.5rem 0;
    border: 0;
    border-top: 1px solid ${colors.text};
  }

  ul, ol {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  li {
    display: inline-block;
  }

  .gatsby-image-wrapper {
    display: block !important;
    max-width: 100%;
  }

  img {
    max-width: 100%;
  }

  .inactive {
    opacity: 0.5;
    pointer-events: none;
  }

  .hoverable {
    cursor: pointer;
  }

  .blocked {
    pointer-events: none;

    > div {
      opacity: 0.25;
    }
  }
  .center {
    text-align: center;
  }

  .loadable {
    position: relative;

    &:after {
      content: '';
      ${absoluteFill};
      background-color: ${colors.bg};
      ${transitionDefault};
    }

    &.loaded {
      &:after {
        opacity: 0;
        visibility: hidden;
      }
    }
  }

  .mobile-only {
    ${respondFrom(breakpoints.md, css`
      display: none;
    `)} 
  }

  .desktop-only {
    ${respondTo(breakpoints.md, css`
      display: none;
    `)} 
  }
`;
