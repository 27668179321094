import * as React from 'react';
import styled from "@emotion/styled"; 
import { css } from "@emotion/core";
import { motion } from 'framer-motion';

import { Stylable } from '~/content/types';
import { respondTo, breakpoints, dimensions } from '~/styles';



/** styles */
export interface StyledSectionProps {
  maxLimitedWidth?: number;
}
const StyledSection = styled(motion.section)<StyledSectionProps>`

  ${props => respondTo(
    props.maxLimitedWidth ? props.maxLimitedWidth : breakpoints.md,
    css`
      width: 100%;
      max-width: 500px;
      margin-left: auto;
      margin-right: auto;
    `
  )}

  > p {
    font-weight: bold;
    font-size: ${dimensions.fontSize.regular}px;
  }
`;

export interface PageSectionProps extends Stylable, StyledSectionProps {}
const PageSection: React.FC<PageSectionProps> = ({ className, children, ...props }) => {
  return (
    <StyledSection className={className} {...props} /*layout*/>
      {children}
    </StyledSection>
  )
}
 
export default PageSection;
export { PageSection };