import React, { useMemo } from 'react';
import styled from "@emotion/styled";
import { motion } from "framer-motion";

import * as utils from "~/utils";
import { css } from "~/styles";


const StyledPill = styled(motion.div)`
  display: inline-block;
  position: absolute;
  transform-origin: top left;
`
const StyledPillInner = styled.span<StylesProps>`
  position: relative;
  display: inline-block;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  svg {
    ${props => !props.disableAnimation ? css`` : css`
      animation: keyframesSelfRotate 1500ms ease-in-out alternate infinite;
    `
    }
  }
`


interface StylesProps {
  disableAnimation?: boolean;
}
interface Props extends StylesProps {
  filled?: boolean;
  index: number;
  total: number;
}
export const BgPill: React.FC<Props> = ({ filled, index, total, disableAnimation }) => {

  const randomAnimationDelay: number = useMemo(() => -Math.random() * 5000, []);
  const randomAnimationDuration: number = useMemo(() => (Math.random() * 2000) + 3000, []);
  const scale: number = useMemo(() => utils.randomBetween(0, 0.6), []);

  return <>
    <StyledPill
      initial={{
        left: `${((index + (filled?1:0))%2 === 0 ? utils.randomBetween(-0.1, 0.4) : utils.randomBetween(0.5, 1.0)) * 0.9 * 100}%`, // left/right half
        top: `${index/total * 100}%`,  // even vertical distances
        scale: scale,
        rotate: utils.randomBetween(-60, 60)
      }}
    >
      <StyledPillInner style={ disableAnimation ? {} : {
        animationDelay: `${randomAnimationDelay}ms`,
        animationDuration: `${randomAnimationDuration}ms`,
        animationName: scale < 0.2 ? 'keyframesOrbitSmall' : scale < 0.4 ? 'keyframesOrbitMedium' : 'keyframesOrbitLarge'
      }}>
      {
        filled ? 
          <motion.svg  width="400" height="250" viewBox="0 0 400 250">
            <path fill="#01522D" fillOpacity="0.2" opacity="0.906994048" d="M253.292 229.306c-50.607 1.912-107.098 5.8-156.987-5.582-24.98-5.699-51.556-13.94-67.119-35.776C-16.377 124.021 3.972 29.934 95.683 19.216c91.711-10.718 137-11.703 215.523.428 3.81.588 7.59 1.38 11.317 2.389 38.588 10.433 67.245 43.84 74.44 83.548 3.545 19.572 1.825 40.01-5.162 58.556-14.239 37.79-45.548 53.095-80.026 59.735-19.314 3.72-39.623 4.722-58.483 5.434z"/>
          </motion.svg>
          :
          <motion.svg width="400" height="250" viewBox="0 0 400 250">
            <path stroke="#097D37" strokeWidth="4" fill="none" d="M253.292 229.306c-50.607 1.912-107.098 5.8-156.987-5.582-24.98-5.699-51.556-13.94-67.119-35.776C-16.377 124.021 3.972 29.934 95.683 19.216c91.711-10.718 137-11.703 215.523.428 3.81.588 7.59 1.38 11.317 2.389 38.588 10.433 67.245 43.84 74.44 83.548 3.545 19.572 1.825 40.01-5.162 58.556-14.239 37.79-45.548 53.095-80.026 59.735-19.314 3.72-39.623 4.722-58.483 5.434z"/>
          </motion.svg>
      }
      </StyledPillInner>
    </StyledPill>
  </>
};