import React, { useCallback, useRef } from "react";
import { Global, css } from "@emotion/core";
import styled from "@emotion/styled";
import { RecoilRoot } from "recoil";
import RecoilLogger from "recoil-logger";


import "modern-normalize";
import { global, colors } from "~/styles";
import Container from "~/components/layout/Container";
import { ImagesPreloader } from "./ImagesPreloader";
import { Footer } from "~/components/layout/Footer";

const StyledLayoutRoot = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.bg};
`;
const StyledLayoutMain = styled.main`
  min-height: 90vh;
  overflow-x: hidden;
`;

interface LayoutRootProps {
  className?: string;
}

const LayoutRoot: React.FC<LayoutRootProps> = ({ children, className }) => {
  const layoutRootRef = useRef<HTMLDivElement>(null);

  const hideLoader = useCallback(() => {
    setTimeout(() => layoutRootRef.current?.classList.add("loaded"), 200);
  }, []);

  return (
    <>
      <Global styles={() => css(global)} />

      <RecoilRoot>
        {/* {
          process.env.NODE_ENV === 'development' && 
          <RecoilLogger />
        } */}
        <StyledLayoutRoot
          className={`loadable ${className}`}
          ref={layoutRootRef}
        >
          <StyledLayoutMain>
            <Container>{children}</Container>
          </StyledLayoutMain>

          <Footer />
        </StyledLayoutRoot>
      </RecoilRoot>

      <ImagesPreloader onLoaded={hideLoader} />
    </>
  );
};

export default LayoutRoot;
