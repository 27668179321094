import React from "react";
import { PageProps } from "gatsby";
import { AnimatePresence, motion } from "framer-motion";
import styled from "@emotion/styled";

import { pageTransition } from "~/animations";
import { fluidRange } from "~/styles";
import LayoutRoot from "./LayoutRoot";
import Header from "~/components/layout/Header";
import GetInspired from "~/components/index/GetInspired";
import { BackgroundPills } from "~/components/layout/BackgroundPills";
import LocaleContext, { ILocaleContext } from "~/localeContext";
import BannerPillContent from "~/components/common/BannerPillContent";
import BannerPrizes from "~/components/prizesPage/Banner";
import CookieBanner from "~/components/layout/CookieBanner";

const StyledPageContainer = styled.div`
  ${fluidRange("paddingTop", "40px", "50px")};
  min-height: 500px;
  position: relative;
  z-index: 1;
`;

interface IPageContext extends ILocaleContext {
  layout?: "empty" | "none";
}
interface MainLayout extends PageProps {
  logoFull?: boolean;
  pageContext: IPageContext;
}

const MainLayout: React.FC<MainLayout> = ({ children, pageContext }) => {
  return (
    <>
      <noscript>
        <iframe
          src="https://www.googletagmanager.com/ns.html?id=GTM-N2GCP4L"
          height="0"
          width="0"
        ></iframe>
      </noscript>
      {
        pageContext.layout !== "none" ? (
          <LocaleContext.Provider value={pageContext}>
            <LayoutRoot>
              <BackgroundPills />
              <Header hideHero={pageContext.layout === "empty"} />

              <AnimatePresence exitBeforeEnter={true}>
                <motion.div
                  key={typeof window !== "undefined" ? window.location.pathname : 0}
                  variants={pageTransition}
                  initial="initial"
                  animate="enter"
                  exit="exit"
                  transition={{
                    duration: 0.2,
                  }}
                  id="gm-page-content"
                >
                  <StyledPageContainer>{children}</StyledPageContainer>
                </motion.div>
              </AnimatePresence>

              {(pageContext as any).layout !== "empty" && <GetInspired />}
              {/* {!!pageContext.promoBanner && <BannerPillContent bannerData={pageContext.promoBanner } />} */}
              {(pageContext as any).layout !== "empty" && <BannerPrizes />}

            </LayoutRoot>
          </LocaleContext.Provider>
        ) : <>
          {children}
        </>
      }
      <CookieBanner />
    </>
  )
};

export default MainLayout;
