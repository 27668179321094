import React from 'react';
import styled from "@emotion/styled";
import { Stylable } from '~/content/types';
import { absoluteFill, safariOnly, css } from "~/styles";


const StyledContainer = styled.div`
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 80%;  /* 5:4 */
  max-width: 100%;
  border-radius: 40px 0 40px 0;
  box-shadow: 0 8px 14px 0 rgba(1, 82, 45, 0.2);

  > * {
    height: 100%;
    ${absoluteFill};
    max-width: none;
    width: auto;

    border: white solid 1px;
    border-radius: 40px 0 40px 0;
    overflow: hidden;

    ${safariOnly(css`
      -webkit-mask-image: -webkit-radial-gradient(white, black);  /* o:h fix for safari */
    `)}
  }

  img, canvas {
    left: 50%  !important;
    transform: translateX(-50%) !important;
    height: 100% !important;
    width: auto !important;
    max-width: none;
    position: absolute;
    top: 0 !important;
  }
`

interface Props extends Stylable {}
export const CardPreviewContainer: React.FC<Props> = ({ children, className }) => {
  return (
    <StyledContainer className={className}>
      {children}
    </StyledContainer>
  )
};