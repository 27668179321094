import React, { useContext } from "react";
import styled from "@emotion/styled";
import { Link } from "gatsby";

// import { apiBasicPagesQuery, apiSocialChannelsQuery } from "~/graphql";
import * as utils from "~/utils";
import {
  absoluteFill,
  respondFrom,
  respondTo,
  breakpoints,
  css,
  colors,
  fontSmoothing,
  transitionDefault,
  fluidRange,
  dimensions,
} from "~/styles";
import Container from "./Container";
import localeContext from "~/localeContext";

const StyledContainer = styled(Container)`
  position: relative;
  padding-bottom: 0 !important;

  ${respondFrom(
    breakpoints.md,
    css`
      height: 100%;
    `
  )}
`;
const StyledContainerInner = styled.div`
  position: relative;

  ${respondFrom(
    breakpoints.md,
    css`
      height: 100%;
    `
  )}

  .sale-text {
    text-align: right;
    font-weight: bold;
    color: rgba(55, 55, 54, 0.54);

    > span {
      display: block;

      ${respondTo(
        breakpoints.md,
        css`
          margin-bottom: 20px;
        `
      )}
    }

    &--desktop {
      min-width: 285px;
      margin-left: 40px;

      ${respondTo(
        breakpoints.md,
        css`
          display: none;
        `
      )}
    }

    &--mobile {
      width: 50%;
      margin-top: 5px;
      padding-right: 30px;

      ${respondFrom(
        breakpoints.md,
        css`
          display: none;
        `
      )}
    }
  }
`;

const StyledFooter = styled.footer`
  min-height: 235px;
  position: relative;

  &:before {
    content: "";
    ${absoluteFill};
    background-image: url("/images/footer-bg-mobile.svg");
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    z-index: 0;
    transform:scaleX(-1);
  }

  ${respondFrom(
    breakpoints.md,
    css`
      height: 235px;
      &:before {
        background-image: url("/images/footer-bg.svg");
      }
    `
  )}
`;

const StyledSocialLinks = styled.nav`
  text-align: right;
  position: absolute;
  color: ${colors.black};
  margin: 0 -10px;
  width: 100%;
  top: 40px;
  left: 0;

  display: flex;
  align-items: flex-start;
  flex-flow: row-reverse;

  ul {
    display: flex;
    justify-content: flex-end;
  }

  li {
    margin: 0;

    ${fluidRange('marginLeft', '4px', '10px', breakpoints.xs, breakpoints.sm)}
    ${fluidRange('marginRight', '4px', '10px', breakpoints.xs, breakpoints.sm)}
  }

  a {
    position: relative;

    img, svg {
      ${transitionDefault};
    }

    &:hover {
      img, svg {
        transform: translateY(-1px);
      }
    }
  }

  img, svg {
    max-width: 44px;
    max-height: 44px;
  }

  ${respondFrom(
    breakpoints.md,
    css`
      top: 36%;
    `
  )}
  ${respondFrom(
    breakpoints.xl,
    css`
      top: 40%;
    `
  )}
`;

const StyledNav = styled.nav`
  text-align: center;
  color: ${colors.darkgrey};
  font-size: 12px;

  ${respondTo(
    breakpoints.md,
    css`
      padding-top: calc(50px + 20vw);
      padding-bottom: 30px;

      display: flex;
      flex-flow: row-reverse;
    `
  )}

  ${respondFrom(
    breakpoints.md,
    css`
      position: absolute;
      top: 70%;
      left: 50%;
      width: 100%;
      transform: translate(-50%, -50%);
    `
  )}
  ${respondFrom(
    breakpoints.xl,
    css`
      top: 77%;
    `
  )}


  a {
    color: ${colors.green};
    ${fontSmoothing};
    text-align: center;
    text-decoration: none;
    padding: 10px 0;
    white-space: nowrap;

    ${transitionDefault};
    transition-property: color;

    &:hover {
      color: ${colors.darkgreen};
    }

    ${respondTo(
      breakpoints.md,
      css`
        text-transform: uppercase;
      `
    )}

    ${respondFrom(
      breakpoints.md,
      css`
        padding: 10px 15px;
      `
    )}
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    ${respondTo(
      breakpoints.md,
      css`
        max-width: 50%;
        text-align: right;
        margin-left: auto;
      `
    )}

    li {
      display: inline-block;
      font-weight: bold;
      font-size: ${dimensions.fontSize.small}px;

      ${respondTo(
        breakpoints.md,
        css`
          display: block;
          line-height: 2.43;

          &:first-of-type {
            margin-bottom: 10px;
          }
          &:last-of-type {
            margin-top: 10px;

            > span {
              margin: 0 2px;
            }
          }
        `
      )}

      ${respondFrom(
        breakpoints.md,
        css`
          line-height: 14px;
          vertical-align: baseline;

          &:not(:first-of-type):not(:last-of-type) {
            font-size: 20px;
          }
          &:first-of-type {
            float: left;
          }
          &:last-of-type {
            float: right;

            > span {
              margin: 0 2px;
            }
          }
        `
      )}

      &.date-wrapper {
        font-size: ${dimensions.fontSize.regular}px;
      }
    }
  }
`;

export const Footer: React.FC<{}> = () => {
  const { basicPages, socialChannels } = useContext(localeContext);

  return (
    <StyledFooter>
      <StyledContainer>
        <StyledContainerInner>
          <StyledSocialLinks>
            <ul>
              {!!socialChannels && socialChannels.map((channel, i) => {
                const uri = channel.link || "";
                const imgPath =
                  uri.indexOf("facebook.com") !== -1
                    ? "/images/icon-fb.svg"
                    : uri.indexOf("instagram.com") !== -1
                    ? "/images/icon-insta.svg"
                    : uri.indexOf("twitter.com") !== -1
                    ? "/images/icon-twitter.svg"
                    : "";
                return (
                  <li key={i}>
                    <a
                      href={channel.link || ""}
                      target="_blank"
                      title={channel.title || ""}
                    >
                      {
                        channel.image ? 
                        <>
                          <span dangerouslySetInnerHTML={{ __html: channel.image || "" }} />
                        </> : 
                        !!imgPath && <img src={imgPath} alt="" />
                      }
                      {}
                    </a>
                  </li>
                );
              })}
            </ul>
            <div className="sale-text sale-text--desktop">
              <span>&#x200E;{ utils.t('Footer sale') }</span>
              <span>{ utils.t('Footer sale 2') }</span>
            </div>
          </StyledSocialLinks>
          <StyledNav>
            <div className="sale-text sale-text--mobile">
              <span>{ utils.t('Footer sale') }</span>
              <span>{ utils.t('Footer sale 2') }</span>
            </div>
            <ul>
            <li>{ utils.t('All rights reserved') }</li>
              {!!basicPages && basicPages.map((page, i) => (
                <li key={i}>
                  <Link to={`${utils.getPath('home')}${utils.slugify(page.title || "")}`}>
                    {page.title}
                  </Link>
                </li>
              ))}
              <li className="date-wrapper"><span>טיק טק</span><span>{new Date().getFullYear()}</span></li>
            </ul>
          </StyledNav>
        </StyledContainerInner>
      </StyledContainer>
    </StyledFooter>
  );
};
