import { selector } from "recoil";
import {
  atomsDefaults,
  words,
  dropArea,
  takeArea,
  cardBackground,
  cardMascot,
  cardCaption,
} from "./";
import { TWord, TMessage, PanInfo } from "~/content/types";
import * as utils from "~/utils";

// TODO: using setters currently causes warning (dev only!):
// https://github.com/facebookexperimental/Recoil/issues/12

// adds word to current message ('words' atom)
export const wordAdd = selector<TWord | null>({
  key: "wordAdd",
  get: () => {
    return null;
  },
  set: ({ get, set }, word_) => {
    if( get(words).length >= utils.consts.MSG_WORDS_MAX ) {
      return;
    }

    const allWords = get(words);
    const word = word_ as TWord;
    const wordsCount = get(selectWordsCountByTextState);
    const c = wordsCount[word.text] ? wordsCount[word.text] + 1 : 1;
    const newWord: TWord = { ...word, key: c, isSelected: true, index: allWords.length };
    set(words, [...allWords, newWord]);
  },
});

// removes word from current message ('words' atom)
export const wordRemove = selector<TWord | null>({
  key: "wordRemove",
  get: () => {
    return null;
  },
  set: ({ get, set }, word_) => {
    const word = word_ as TWord;
    set(
      words,
      get(words).filter(w => w !== word)
    );
  },
});

// removes all words from message
export const wordsClear = selector({
  key: "wordsClear",
  get: () => {
    return null;
  },
  set: ({ set }) => {
    set(words, []);
  },
});

// set message - multiple atoms
export const messageSet = selector<TMessage | null>({
  key: "messageSet",
  get: () => {
    return null;
  },
  set: ({ set }, message_) => {
    const { words, background, mascot, caption } = message_ as TMessage;

    set(wordsClear, null);
    words.map((word, i) => set(wordAdd, utils.createWord({ text: word, index: i })));
    
    if( background ) {
      set(cardBackground, background);
    }
    if( mascot ) {
      set(cardMascot, mascot);
    }
    if (caption) {
      set(cardCaption, caption);
    }
  },
});

// clear all
export const messageClear = selector({
  key: "messageClear",
  get: () => {
    return null;
  },
  set: ({ set }) => {
    set(wordsClear, null);
    set(cardMascot, atomsDefaults.cardMascot);
    set(cardBackground, atomsDefaults.cardBackground);
  },
});

interface DragDropData {
  // TODO -> types
  word: TWord;
  info: PanInfo;
}
export const wordDrop = selector<DragDropData | null>({
  key: "wordDrop",
  get: () => {
    return null;
  },
  set: ({ get, set }, data_) => {
    const data = data_ as DragDropData;
    if (
      utils.isWithin(data?.info?.point, get(dropArea)) &&
      !data.word.isSelected
    ) {
      set(wordAdd, data.word);
    } else if (
      utils.isWithin(data.info.point, get(takeArea)) &&
      data.word.isSelected
    ) {
      set(wordRemove, data.word);
    }
  },
});

interface TWordsCountByText {
  text: string;
  count: number;
}
// TODO: is it nice?..
export const selectWordsCountByTextState = selector<TWordsCountByText>({
  key: "selectWordsCountByText",
  get: ({ get }): TWordsCountByText => {
    return get(words).reduce((countByText, curr, idx) => {
      if (!countByText[curr.text]) {
        countByText[curr.text] = 1;
      } else {
        countByText[curr.text]++;
      }
      return countByText;
    }, {} as TWordsCountByText);
  },
});
