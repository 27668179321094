import { dimensions, animation, breakpoints, colors } from './variables';
// import { bounceLeftRight } from "~/animations";
import { css, SerializedStyles } from '@emotion/core';
export { css } from '@emotion/core';

import { fluidRange as polishedFluidRange } from 'polished';

export const getEmSize = (size: number) => size / dimensions.fontSize.regular;

/* --- animation helpers */

const transitionDefaultProperties = `transform, opacity, visibility, color, background-color, stroke`;

export const transitionDefault = css`
  transition: ${animation.fast}ms ease-out;
  transition-property: ${transitionDefaultProperties};
  will-change: ${transitionDefaultProperties};
`;
/* --- repeating elements */

export const fontSmoothing = css`
  -webkit-font-smoothing: antialiased;
  -moz-font-smoothing: antialiased;
  -o-font-smoothing: antialiased;
`;

export const loader = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &:after {
    content: '■';
    display: inline-block;
    top: 50%;
    left: 50%;
    transform: translate(0, -26%) scaleY(2) scaleX(0);
    opacity: 0;

    transition: all ${animation.fast}ms ease;
    transition-property: transform, opacity;
  }
`

export const loaderActive = css`
  &:after {
    opacity: 1;
    transform: translate(0, -26%) scale(2);
  }

  /* ${bounceLeftRight};
  animation-name: bounceLeftRight;
  animation-duration: 0.8s;
  animation-iteration-count: infinite;
  animation-timing-function: ease-in-out; */
`

/* --- styling helpers */

export const absoluteFill = css`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`;

export const absoluteCenter = css`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;


/* --- responsive helpers */

export const fluidRange = (
  prop: string,
  fromSize: string | number,
  toSize: string | number,

  minScreen?: number,
  maxScreen?: number
) => css`
  ${polishedFluidRange(
    { prop, fromSize, toSize },
    `${minScreen || breakpoints.sm}px`,
    `${maxScreen || breakpoints.xl}px`
  )}
`;

export const respondFrom = (minW: number, content: SerializedStyles) => css`
  @media (min-width: ${minW}px) {
    ${content}
  }
`;
export const respondTo = (maxW: number, content: SerializedStyles) => css`
  @media (max-width: ${maxW - 1}px) {
    ${content}
  }
`;

export const safariOnly = (content: SerializedStyles) => css`
  @media not all and (min-resolution:.001dpcm)
  { @supports (-webkit-appearance:none) {
    ${content}
  }}
`


export const mixins = {
  transitionDefault,
  fontSmoothing,

  loader,
  loaderActive,
  
  absoluteFill,
  absoluteCenter,

  fluidRange,
  respondFrom,
  respondTo,
  safariOnly,
};
