import React, { useEffect, useState } from "react";

interface Props {
  onLoaded?: () => void;
}
export const ImagesPreloader: React.FC<Props> = ({ onLoaded }) => {
  const [isLoading, setIsLoading] = useState(true);

  const cacheImages = (imagesSrc: string[]) => {
    imagesSrc.forEach(src => {
      new Promise((resolve, reject) => {
        const img = new Image();
        img.src = src;
        img.onload = resolve;
        img.onerror = reject;
      });
    });

    setIsLoading(false);
    onLoaded ? onLoaded() : null;
  };

  useEffect(() => {
    // note: only images which we really don't want lazy loading for
    const images = [
      "/images/pill-bg.png",
      "/images/packshot.png",

      "/images/backgrounds/bg_blue.png",
      "/images/backgrounds/bg_dark_green.png",
      "/images/backgrounds/bg_green.png",
      "/images/backgrounds/bg_orange.png",
    ];

    cacheImages(images);
  }, []);

  return <></>;
};
