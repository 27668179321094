// @welldone-software/why-did-you-render
// import React from 'react';
export const onClientEntry = () => {
  // if (process.env.NODE_ENV !== 'production') {
  //   const whyDidYouRender = require('@welldone-software/why-did-you-render')
  //   whyDidYouRender(React, {
  //     trackAllPureComponents: true
  //   })
  // }
}

// disable gatsby's scroll to top on page change
export const shouldUpdateScroll = () => {
  return false;
};