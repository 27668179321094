import React, { useContext } from "react";
import { NodeHeroImage, NodePackshotImage, NodePromoBanner } from "./graphql";
import { NodePage, NodeSocialChannel, Translation } from "./graphql/types";

export interface ILocaleContext {
  translation: Translation;
  socialChannels: NodeSocialChannel[];
  basicPages: NodePage[];
  heroImage?: NodeHeroImage;
  packshotImageUrl?: string;
  promoBanner?: NodePromoBanner;
}

const localeContext = React.createContext<ILocaleContext>({} as ILocaleContext);

export const t = (text: string, html: boolean = false) => {
  const { translation } = useContext(localeContext);
  
  const translated = 
    translation.messages && translation.messages[text] ? 
    `${translation.messages[text]}` : text;

  return html ? 
    <span dangerouslySetInnerHTML={{ __html: translated }} /> : translated;
}

export default localeContext;