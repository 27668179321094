import React, { useCallback, forwardRef, useState, useEffect } from 'react';
import { Stage, Layer, Image, Group, Text } from "react-konva";
import { Stage as StageType } from 'konva/types/Stage';
import styled from "@emotion/styled";
import useImage from "use-image";

import { absoluteFill } from "~/styles";
import { TWord } from '~/content/types';
import { consts } from '~/utils';
import { CardPreviewContainer } from "~/components/common/CardPreviewContainer";

const FontFaceObserver = require('fontfaceobserver');
const font = new FontFaceObserver('Heebo', {
  weight: 600
});


const StyledStage = styled(Stage)`
  
  /* center image in container */
  .konvajs-content {
    height: 100% !important;
    width: auto !important;

    canvas {
      /* -> CardPreviewContainer */
    }
  }
`


/** card constants / helpers */
const imgHeight = 630;
const imgProportion = 1.905;
const imgWidth = imgHeight * imgProportion;
const origImgHeight = 375;
const origImgWidth = 474;
const previewFactor = 1.3;
const stageWidth = imgWidth/previewFactor;
const stageHeight = imgHeight/previewFactor;
const scaleFactor = imgHeight / origImgHeight;
const horizontalShift = 120;
const maxItemsInRow = 5;

const getHor = (w: number) => w*scaleFactor/previewFactor;
const getVert = (w: number) => w*scaleFactor/previewFactor;
/** /card constants */


interface Props {
  words: TWord[];
  background: string;    // TODO
  mascot: string;
  caption?: string;
}
export const CardPreview = forwardRef<StageType, Props>( 
  ({ words, background, mascot, caption }, 
  stageRef?: React.Ref<StageType>) => {

  const [fontLoaded, setFontLoaded] = useState(false);
  const mascotColor: string = background === 'orange' ? 'green' : 'orange';
  const [backgroundImage] = useImage(`/images/backgrounds/bg_${background}.png`);
  const [mascotImage] = useImage(`/images/mascots/${mascot}_${mascotColor}.png`);
  const [logo] = useImage('/images/logo.png');
  const [pillBg] = useImage(`/images/pill-bg.png`);


  // Handle loading effects.
  useEffect(() => {
    font.load().then(function () {
      setFontLoaded(true);
    })
  }, []);

  const calculateXPosition = (index) => {
    let items = words.length;
    let pos = 0;

    if (words.length - maxItemsInRow > 0 && index > 4) {
      items = words.length - maxItemsInRow;
    }

    switch(items) {
      case 1:
        pos = 170;
        break;
      case 2:
        pos = 120;
        break;
      case 3:
        pos = 86;
        break;
      case 4:
        pos = 40;
        break;
    }

    return pos;
  };

  const getPillX = useCallback((index: number) => {
    const baseX = 30;
    const baseMultiplier = 85;
    let increaseXBy = baseMultiplier * index;

    if (index >= maxItemsInRow) {
      increaseXBy = baseMultiplier * (index - maxItemsInRow);
    }

    if ((words.length % maxItemsInRow) !== 0) {
      increaseXBy += calculateXPosition(index)
    }

    return baseX + increaseXBy;
  }, [words.length]);

  const getPillY = useCallback((index: number) => {
    const baseY = 20;
    let increaseYBy = 0;

    if (index >= maxItemsInRow) {
      increaseYBy = 50;
    }

    if (words.length <= maxItemsInRow) {
      increaseYBy = 25;
    }

    return baseY + increaseYBy;
  }, [words.length]);


  return (
    <CardPreviewContainer>
      <StyledStage 
        width={stageWidth} 
        height={stageHeight} 
        ref={stageRef}
        pixelRatio={1}
        alt={words.map( w => w.text ).join(' ')}
        title={words.map( w => w.text ).join(' ')}
        preventDefault={false}
      >
        <Layer>
          <Image
            x={0}
            y={0}
            width={stageWidth}
            height={stageHeight}
            image={backgroundImage}
            scale={{x:1, y: 1}}
            preventDefault={false}
          />
          <Group

          >
            {
              words
                .map((word, index) => (
                  <Image
                    x={getHor(getPillX(index) + horizontalShift)}
                    y={getVert(getPillY(index))}
                    width={getHor(76)}
                    height={getVert(38)}
                    image={pillBg}
                    scale={{x: 1, y: 1}}
                    key={index}
                    preventDefault={false}
                  />
                ))
            }
            {
              !!fontLoaded && 
              words.map((word, index) => (
                  <Text
                    text={word.text}
                    x={getHor(getPillX(index) + horizontalShift)}
                    y={getVert(getPillY(index))}
                    width={getHor(76)}
                    height={getVert(38)}
                    fontFamily="Heebo"
                    fontSize={getVert(16)}
                    fontStyle="600"
                    fill="black"
                    align="center"
                    verticalAlign="middle"
                    wrap="nowrap"
                    scale={{x: 1, y: 1}}
                    key={index}
                    preventDefault={false}
                  />
                ))
            }
          </Group>
          <Image
            image={mascotImage}
            x={getHor(25 + horizontalShift)}
            y={getVert(120)}
            height={getVert(234)}
            width={getHor(311)}
            scale={{x: 1, y: 1}}
            preventDefault={false}
          />
          <Image
            image={logo}
            x={getHor(360 + horizontalShift)}
            y={getVert(270)}
            width={getHor(108)}
            height={getVert(96)}
            scale={{x: 1, y: 1}}
            preventDefault={false}
          />
          {
            !!caption && fontLoaded &&
            <Text
              text={`${caption.slice(0, consts.NAME_MAX_CHARS)}`}
              x={stageWidth/2 + getHor(27)}
              y={getVert(words.length > 5 ? 120 : 100)}
              width={stageWidth/4}
              fontFamily="Heebo"
              fontSize={28}
              fontStyle="600"
              fill="white"
              align="right"
              verticalAlign="top"
              wrap="word"
              preventDefault={false}
            />
          }
        </Layer>
      </StyledStage>
    </CardPreviewContainer>
  )
});