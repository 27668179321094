import React, { memo, useEffect, useState } from 'react';
import styled from "@emotion/styled";

import * as utils from "~/utils";
import { absoluteFill } from '~/styles';
import { BgPill } from './BgPill';


const StyledContainer = styled.div`
  ${absoluteFill};
  z-index: 0;
  width: 100%;
  overflow: hidden;
`

// const pillsAmount = !utils.isSSR ? Math.floor(window.innerHeight/100 * 2.0) : 20;
export const BackgroundPills: React.FC<{}> = memo(() => {
  const [pillsAmount, setPillsAmount] = useState<number>(0)
  const [animationEnabled, setAnimationEnabled] = useState(false);

  /*
  useEffect(() => {
    const MAX_MEASURES = 10;
    let measures = 0;
    let smoothMeasures = 0;
    let currentFps = 0;
    const checkFps = () => {
      currentFps = utils.getFps();
      console.log(currentFps);
      if( currentFps >= 50 ) {
        smoothMeasures++;
      } 

      if( measures < MAX_MEASURES ) {
        setTimeout(() => {
          checkFps();
        }, 500);
        measures++;
      }
      else {
        // console.log(smoothMeasures);
        setAnimationEnabled( smoothMeasures >= MAX_MEASURES/2 );
      }
    }

    checkFps();
  }, []);
  */

  // count pills amount after mount
  useEffect(() => {
      // how many screen heights is the whole page height
    const scrensAmount = window.document.documentElement.offsetHeight / window.screen.height;
      // pills per screen varies depending on proportions
    const pillsPerScreen = 10 * ( window.innerWidth / window.innerHeight );      
      // total number of pills             
    const amount = !utils.isSSR && window.document.documentElement.offsetHeight ? 
      Math.floor( scrensAmount * pillsPerScreen ) // 8 per screen height
      : 20  // default;

    setPillsAmount(
      2 * Math.floor(amount/2)
    );
  }, []);

  return (
    pillsAmount ? <StyledContainer>
      {
        [...Array(pillsAmount/2).keys()].map( i => (
          <BgPill key={i} index={i} total={pillsAmount/2} 
            disableAnimation={ !animationEnabled || utils.isMobile() }
          />
        ))
      }
      {
        [...Array(pillsAmount/2).keys()].map( i => (
          <BgPill key={i} index={i} total={pillsAmount/2} filled={true} 
            disableAnimation={ !animationEnabled || utils.isMobile() }
          />
        ))
      }
    </StyledContainer>
    : <></>
  )
}, () => true);