import * as React from "react";
import styled from "@emotion/styled"; 
import { css } from "@emotion/core";
import { Link } from "gatsby";
import { AnimatePresence, motion } from "framer-motion";

import * as utils from "~/utils";
import { fluidRange, respondTo, breakpoints } from '~/styles';
import HeaderHero from "./HeaderHero";


/** styles */
const StyledHeader = styled.header<Props>`
  z-index: 2;
  ${fluidRange('paddingTop', '25px', '35px')};

  ${props => respondTo(
    breakpoints.sm,
    css`
      height: ${props.hideHero ? 'auto' : '100vh'};
      display: flex;
      flex-flow: column;
    `
  )}
`

const StyledLogo = styled(motion.img)`
  ${fluidRange('width', '135px', '153px')};
  ${fluidRange('marginTop', '-35px', '-40px')}
  ${fluidRange('marginRight', '-26px', '-30px')}
  ${fluidRange('marginBottom', '-35px', '-40px')}

  position: absolute;
`


interface Props {
  hideHero?: boolean;
}
const Header: React.FC<Props> = props => (
  <StyledHeader hideHero={props.hideHero}>
    {
      !utils.isInIframe() &&
      <Link to={utils.getPath('home')}>
        <StyledLogo alt="logo"
          src="/images/logo.png" 
          srcSet="/images/logo@2x.png 2x, /images/logo@3x.png 3x"
        />
      </Link>
    }

    {
      !props.hideHero &&
      <HeaderHero />
    }
  </StyledHeader>
)

export default Header;