import React, { Component } from 'react';
import styled from "@emotion/styled";
import Cookies from 'js-cookie';

import { animation, colors, respondFrom, breakpoints, css, dimensions } from '~/styles';

import Container from "~/components/layout/Container";
import Button from "~/components/common/Button";

const CookieBannerWrapper = styled.div`
  z-index: 10;
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background: ${colors.darkpalegreen};
  transform: translateY(100%);
  opacity: 0;
  transition: all ease-in ${animation.fast}ms;
  box-shadow: 0 -8px 14px 0 rgba(1, 82, 45, 0.2);

  &.is-visible {
    transform: translateY(0);
    opacity: 1;
  }
`;

const CookieBannerInner = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 30px 0;

  ${respondFrom(
    breakpoints.md,
    css`
      flex-flow: row;
    `
  )};
`;

const CookieBannerText = styled.div`
  > p {
    margin: 0;
    color: white;
    font-family: Heebo, sans-serif;
    text-align: right;
    font-size: ${dimensions.fontSize.small}px;
    line-height: 21px;
    font-weight: 300;
    -webkit-font-smoothing: antialiased;
  }
`;

const ButtonWrapper = styled.div`
  min-width: 135px;
  margin-top: 20px;

  ${respondFrom(
    breakpoints.md,
    css`
      margin-right: 50px;
      margin-top: 0;
    `
  )};

  ${respondFrom(
    breakpoints.lg,
    css`
      margin-right: 150px;
    `
  )};
`;

const StyledButton = styled(Button)`
  background-color: white;
  color: black;
`;

class CookieBanner extends Component {
  state = {
    isVisible: false,
    cookiesName: 'tictac-gm-cookies'
  };

  componentDidMount() {
    const { cookiesName } = this.state;

    if (!Cookies.get(cookiesName)) {
      setTimeout(() => {
        this.setState({ isVisible: true });
      }, 300);
    };
  };

  handleCookieAccept = () => {
    const { cookiesName } = this.state;
    
    this.setState({ isVisible: false}, () => {
      Cookies.set(cookiesName, true, { expires: 365 });
    })
  };

  render() {
    return (
      <CookieBannerWrapper className={this.state.isVisible ? 'is-visible' : ''}>
        <Container className="no-bottom-padding">
          <CookieBannerInner dir="rtl">
            <CookieBannerText>
              <p>
              אתר זה משתמש בעוגיות טכניות (כולל אלו של צדדים שלישיים) (וכן בעוגיות פרופיל על מנת לשלוח לך הודעות פרסומיות המתאימות להעדפות הגלישה שלך ברשת). אם אתה רוצה ללמוד עוד על עוגיות, או לשנות את העדפות הדפדפן של בכל הנוגע לעוגיות (עד למצב של חסימת התקנה של עוגיות מכל סוג שהוא), לחץ כאן. אם תמשיך לגלוש על ידי כניסה לכל חלק או בחירה בכל פריט באתר (לדוגמא, על ידי לחיצה על תמונה או קישור), אתה מביע בזאת את הסכמתך לשימוש בעוגיות.
              </p>
            </CookieBannerText>
            <ButtonWrapper>
              <StyledButton onClick={this.handleCookieAccept}>
                אני מקבל
              </StyledButton>
            </ButtonWrapper>
          </CookieBannerInner>
        </Container>
      </CookieBannerWrapper>
    );
  }
}

export default CookieBanner;