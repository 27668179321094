import React, { useCallback, useMemo, memo } from "react";
import { navigate } from "gatsby";
import styled from "@emotion/styled"; 
import { css } from "@emotion/core";
import { useSetRecoilState } from "recoil";
import queryString, { ParsedQuery } from "query-string";
import LazyLoad from 'react-lazyload';

import * as utils from "~/utils";
import { fluidRange, respondFrom, breakpoints, transitionDefault } from '~/styles';
import { PageSection, StyledSectionProps } from "~/components/common";
import { CardPreview } from "~/components/common/CardPreview";
import { TMessage } from "~/content/types";
import { messageSet } from "~/recoil";


/** styles */
const StyledSection = styled(PageSection)<StyledSectionProps & MessageCreatorProps>`
  ${fluidRange('paddingBottom', '50px', '90px')}
  display: ${props => props.hidden ? 'none' : 'block'};
`

const StyledCardsCont = styled.div`
  ${respondFrom(
    breakpoints.lg,
    css`
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      flex-wrap: wrap;
      margin: 0 -10px;
    `
  )}
`

const CardCont = styled.div`
  display: block;
  width: 100%;
  max-width: 350px;
  margin: 0 auto 20px;
  cursor: pointer;

  ${transitionDefault}
  transform: translateZ(0);

  &:hover {
    transform: translateY(-1px);
  }
  &:active {
    transform: translateY(0);
  }

  ${respondFrom(
    breakpoints.md,
    css`
    `
  )}

  ${respondFrom(
    breakpoints.lg,
    css`
      flex-basis: ${100/3}%;
      width: ${100/3}%;
      max-width: none;
      box-sizing: border-box;
      padding: 0 10px;
      flex-grow: 0;
      flex-shrink: 0;
    `
  )}
`

interface QueryData extends ParsedQuery<string> {
  showAllMessages?: string;
}
export interface MessageCreatorProps {
  hidden?: boolean;
}
const GetInspired: React.FC<MessageCreatorProps> = memo(props => {
  const setMessageState = useSetRecoilState(messageSet);

  let showAllMessages: boolean = useMemo(() => {
    if( !utils.isSSR ) {
      const queryData: QueryData = queryString.parse(window.location.search) as QueryData;
      return  !!queryData.showAllMessages || false;
    }
    return false
  }, []);

  const pathHome = utils.getPath('home');
  const handleClick = useCallback((message: TMessage) => {
    const messageSort = {
      background: message.background,
      mascot: message.mascot,
      words: message.words
    }

    utils.setMessage(messageSort, setMessageState);
    utils.scrollToMainContent(true);

    if( !utils.isSSR && window.location.pathname !== pathHome ) {
      navigate( pathHome );
    }
  }, []);

  return ( 
    <StyledSection maxLimitedWidth={breakpoints.lg} hidden={props.hidden}>
      <h1>{ utils.t('Get inspired!') }</h1>
      <p>{ utils.t('Check out other gentle cards', true) }</p>

      <StyledCardsCont>
        {
          (showAllMessages ? utils.getPreparedMessages() : utils.getRandomPreparedMessages(3) )
          .map( (card, i) => {
            const { words, ...cardProps } = card;
            const wordsReverse = utils.changeWordsOrder(words);

            return (
              // TODO: separate component for clickable card -> full handle function

              <CardCont key={words.join('-')} onClick={() => handleClick(card)}>
                <LazyLoad once offset={200}>
                  <CardPreview {...cardProps} words={wordsReverse.map( word => utils.createWord({ text: word, index: -1 }) )} /> 
                </LazyLoad>
              </CardCont>
            )
          })
        }
      </StyledCardsCont>
    </StyledSection>
  );
});
 
export default GetInspired;