import { atom, RecoilState } from 'recoil';
import { TWord, AxisBox2D } from '~/content/types';


/** helpers */

function atomWithLog<T>(atomConf): RecoilState<T> {
  return atom({
    ...atomConf,
    persistence_UNSTABLE: {
      type: 'log'
    },
  })
}

/** defaults */
export const atomsDefaults = {
  words: [] as TWord[],
  cardMascot: 'door',
  cardBackground: 'orange',
  cardCaption: '',
  dropArea: { x: { min: 0, max: 0 }, y: { min: 0, max: 0 } },
  takeArea: { x: { min: 0, max: 0 }, y: { min: 0, max: 0 } }
}

/** atoms */

// selected words!
export const words = atomWithLog<TWord[]>({
  key: 'words', 
  default: atomsDefaults.words
});

// current position/dimensions of take area
export const takeArea = atomWithLog<AxisBox2D>({
  key: 'takeArea',
  default: atomsDefaults.takeArea
})

// current position/dimensions of drop area
export const dropArea = atomWithLog<AxisBox2D>({
  key: 'dropArea',
  default: atomsDefaults.dropArea
})

// card name user input data
export const cardCaption = atomWithLog<string>({
  key: 'cartCaption',
  default: atomsDefaults.cardCaption
});

// card customization form data - background
export const cardBackground = atomWithLog<string>({
  key: 'cardBackground',
  default: atomsDefaults.cardBackground
});

// card customization form data - mascot
export const cardMascot = atomWithLog<string>({
  key: 'cardMascot',
  default: atomsDefaults.cardMascot
});

// generated image (data url)
export const cardPreview = atomWithLog<string>({
  key: 'previewImageData',
  default: null
})
