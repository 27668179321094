import React from 'react';
import styled from "@emotion/styled";
import { Link } from "gatsby";
import { respondFrom, breakpoints, css } from "~/styles";

const BannerWrapper = styled.a`
  max-width: 350px;
  width: 100%;
  margin: auto;
  background-color: white;
  z-index: 2;
  box-shadow: 0 8px 14px 0 rgba(1, 82, 45, 0.2);
  position: relative;
  overflow: hidden;
  padding-bottom: 150px;

  .banner {
    &-logo {
      position: absolute;
      top: 10px;
      left: 10px;
      width: 55px;
    }
    &-mascot {
      max-width: 120px;
      display: block;
      margin-right: auto;
      margin-top: -10px;
      margin-left: 20px;
    }
    &-button {
      position: absolute;
      left: 20px;
      bottom: 80px;
      width: 100px;
      z-index: 2;
    }
    &-header {
      padding: 30px 20px 5px;
    }
    &-text {
      padding: 0 20px;
    }
    &-shape {
      position: absolute;
      right: 0;
      bottom: 0;
      max-width: none;
    }
    &-xbox {
      position: absolute;
      right: 10px;
      bottom: 80px;
      height: 150px;
    }
  }

  ${respondFrom(
    breakpoints.md,
    css`
      padding-bottom: 140px;
      max-width: 450px;

      .banner {
        &-logo {
          width: 75px;
        }
        &-xbox {
          height: 200px;
        }
        &-mascot {
          max-width: 180px;
        }
        &-button {
          width: 120px;
          bottom: 50px;
        }
      }
    `
  )};

  ${respondFrom(
    breakpoints.lg,
    css`
      width: 850px;
      max-width: none;
      height: 285px;

      .banner {
        &-logo {
          position: absolute;
          top: 15px;
          left: 15px;
        }
        &-header {
          position: absolute;
          top: 15px;
          right: 225px;
          width: 245px;
          padding: 0;
        }
        &-text {
          position: absolute;
          bottom: 30px;
          left: 205px;
          width: 400px;
          z-index: 2;
        }
        &-mascot {
          position: absolute;
          top: 0;
          left: 120px;
          width: 140px;
          margin: 0;
        }
        &-button {
          left: 30px;
          bottom: 20px;
          width: 140px;
        }
        &-xbox {
          right: 30px;
          top: 20px;
          height: 180px;
        }
      }
    `
  )};
`;

const BannerFooterText = styled.div`
  position: absolute;
  bottom: 10px;
  right: 20px;
  color: white;
  text-align: right;
  font-size: 14px;
  font-family: Heebo, sans-serif;
  font-weight: 700;
`;

const PrizesBanner = () => {
  return (
    <BannerWrapper href="/prizes" target="_blank">
      <img className="banner-logo" src="/images/prizes/prizes-tictac-logo.png" alt="Tic Tac logo" />
      <img className="banner-header" src="/images/prizes/logo-prizes.png" alt="Banner logo" />
      <img className="banner-text" src="/images/prizes/prizes-banner-text-1.png" alt="Text image" />
      <img className="banner-mascot" src="/images/prizes/prizes-mascot.png" alt="Mascot image" />
      <img className="banner-shape" src="/images/prizes/prizes-banner-shape-1.png" alt="Banner shape" />
      <BannerFooterText>
        <div><span>*</span><span>תקופת הפעילות 25.2.21-4.2.21</span></div>
        <div>*בכפוף לתקנון הפעילות</div>
      </BannerFooterText>
      <img className="banner-xbox" src="/images/prizes/xbox.png" alt="Xbox" />
    </BannerWrapper>
  );
};

export default PrizesBanner;