import * as React from "react";
import styled from "@emotion/styled";
import { css } from "@emotion/core";

import { fonts, colors, animation, transitionDefault, dimensions } from '~/styles';

/** styles */
export const buttonShadow = css`
  box-shadow: 0 8px 14px 0 rgba(1, 82, 45, 0.2);
`;
export const buttonStyling = css`
  display: inline-block;
  width: 100%;
  position: relative;
  border-radius: 22px;
  border: none;
  outline: none;
  background: ${colors.orange};
  color: ${colors.white};
  padding: 14px 24px 12px;
  font-family: ${fonts.headers};
  text-transform: uppercase;
  letter-spacing: 0.156em;
  /* white-space: nowrap; */
  ${buttonShadow};
  cursor: pointer;
  font-size: ${dimensions.fontSize.regular}px;
  font-weight: bold;

  ${transitionDefault};

  /* span {
    display: inline-block;

    transition: all ${animation.fast}ms ease;
    transition-property: transform, background-color;
  } */

  &:focus {
    
  }

  &:hover {
    transform: translateY(-1px);
  }

  &:active {
    transform: translateY(0);
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.7;
  }

  &.large {
    font-size: ${dimensions.fontSize.large}px;
  }
`;


const StyledButton = styled.button<Props>`
  ${buttonStyling};

  ${props => props.secondary && css`
    background-color: ${colors.darkgreen};
  `};
`

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  secondary?: boolean;
}
const Button: React.FC<Props> = props => (
  <StyledButton {...props} >
    <span>{props.children}</span>
    <b></b>
  </StyledButton>
)

export default Button;
export { Button };