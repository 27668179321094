import * as React from 'react';
import styled from '@emotion/styled';
import { fluidRange } from '~/styles';

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  ${fluidRange('paddingLeft', '25px', '40px')}
  ${fluidRange('paddingRight', '25px', '40px')}
  ${fluidRange('paddingBottom', '30px', '60px')}

  &.no-bottom-padding {
    padding-bottom: 0;
  }
`;

interface Props {
  className?: string;
}

const Container: React.FC<Props> = ({ children, className }) => (
  <StyledContainer className={className}>{children}</StyledContainer>
);

export default Container;
