import React, { useCallback, useContext } from "react";
import styled from "@emotion/styled"; 
import { css } from "@emotion/core";
import Lottie from 'react-lottie';

import * as utils from "~/utils";
import * as animationData from '~/images/lottie-svg/gift.json';

import { fluidRange, respondFrom, respondTo, breakpoints, fonts } from '~/styles';
import localeContext from "~/localeContext";


/** styles */

const ImagesContainer = styled.div`
  ${fluidRange('marginTop', '70px', '30px')}

  ${respondTo(
    breakpoints.sm,
    css`
      text-align: center;
      height: calc(100vh - 120px);  /* stretch vertically, leave space for logo */
      display: flex;
      flex-flow: column;
      justify-content: space-between;
    `
  )}

  ${respondFrom(
    breakpoints.sm,
    css`
      display: flex;
      flex-flow: row;
      justify-content: space-between;
      align-items: flex-end;
      ${fluidRange('paddingBottom', '20px', '50px')}
    `
  )}
`

const StyledImg = styled.div`

  ${respondTo(
    breakpoints.sm,
    css`
      max-width: 280px;
      margin: 0 auto;
      text-align: center;

      img, svg {
        max-height: calc((100vh - 190px)/2); /* logo + text on bottom need to always fit */
      }
    `
  )}

  ${respondFrom(
    breakpoints.sm,
    css`
      flex-basis: 55%;
      width: 55%;

      &:first-of-type {
        flex-basis: 45%;
        width: 45%;

        ${fluidRange('paddingLeft', '20px', '10px')}
      }
      &:nth-of-type(2) {
        ${fluidRange('paddingRight', '20px', '10px')}
      }
    `
  )}
`
const StyledImgTitle = styled(StyledImg)``

const StyledTitleSubtitle = styled.div`
  text-align: left;
  font-family: ${fonts.headers};
  font-weight: bold;

  ${fluidRange('fontSize', '12px', '20px')}
  ${fluidRange('marginTop', '0px', '15px')}
  ${fluidRange('marginBottom', '0px', '15px')}

  ${respondTo(
    breakpoints.sm,
    css`
      display: none;
    `
    )
  }
`

const StyledImgGift = styled(StyledImg)`
  text-align: right;
  transform: scale(1.2) translateY(20px);

  ${respondTo(
    breakpoints.sm,
    css`
      transform: scale(1.2);
    `
    )
  }

  > *, svg {
    max-width: 100%;
    max-height: 100%;
  }
`

const StyledTitleSubtitleMobile = styled.div`
  text-align: center;
  font-family: ${fonts.headers};
  font-weight: bold;
  text-transform: uppercase;
  font-size: 12px;
  ${fluidRange('fontSize', '12px', '16px', breakpoints.xs, breakpoints.sm)}
  margin-top: 6vh;
  position: relative;
  padding: 2vh 0 8vh 0;
  cursor: pointer;

  ${respondFrom(
    breakpoints.sm,
    css`
      display: none;
    `
    )
  }
`

const StyledTitleSubtitleMobileArrow = styled.span`
  position: absolute;
  width: 25px;
  height: 15px;
  background-image: url('/images/arrow-down-white.svg');
  background-repeat: no-repeat;
  background-position: center center;
  background-size: contain;
  left: 50%;
  bottom: calc(8vh - 15px);
  transform: scale(0.6) translate(-50%, 6px);
`


interface Props {}
const HeaderHero: React.FC<Props> = () => {
  const { heroImage } = useContext(localeContext);

  const scrollToContent = useCallback(() => {
    utils.scrollToMainContent();
  }, []);
  
  const defaultOptions = {
    loop: true,
    autoplay: true, 
    animationData: (animationData as any).default
  };

  return (
    <ImagesContainer>
      <StyledImgTitle>
        {
          !!heroImage && heroImage?.field_hero_image ? 
            <img src={`data:image/svg+xml;utf8,${encodeURIComponent(heroImage?.field_hero_image)}`} /> :
            <img src="/images/gentleness-is-in-little-things.svg" alt="" />
        }
        
        <StyledTitleSubtitle>
          { utils.t('send it to the world!') }
        </StyledTitleSubtitle>
      </StyledImgTitle>
      <StyledImgGift>
        <Lottie options={defaultOptions}
            height={'auto'}
            width={'100%'}
            isClickToPauseDisabled={true}
          />
      </StyledImgGift>

      <StyledTitleSubtitleMobile onClick={ scrollToContent }>
        { utils.t('send it to the world!') }
        <StyledTitleSubtitleMobileArrow />
      </StyledTitleSubtitleMobile>
    </ImagesContainer>
  )
}

export default HeaderHero;