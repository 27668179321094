
/** animations */
export const pageTransition = {
  initial: {
    opacity: 0, 
    y: 10
  },
  enter: {
    opacity: 1,
    y: 0
  },
  exit: {
    opacity: 0,
    y: -10
  }
  
}
