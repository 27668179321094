import { navigate } from "gatsby";
import { animateScroll as scroll } from "react-scroll";
import { SetterOrUpdater } from "recoil";
import { isEqual, find } from "lodash";

import * as utils from "~/utils";
import { AxisBox2D, TMessage } from "~/content/types";
import { paths, isSSR } from "./";
import cardsData from "~/data/cards.json";

declare global {
  interface Window {
    dataLayer: {
      push: Function;
      event: string;
      'gtm.start': number;
      virtualPagePath: string;
      virtualPageURL: string;
      transactionId: string | number;
      transactionTotal: string | number;
      userId: string | number;
    };
  }
}

export const isWithin = (point: { x: number; y: number }, area: AxisBox2D) => {
  return (
    point.x >= area.x.min &&
    point.x <= area.x.max &&
    point.y >= area.y.min &&
    point.y <= area.y.max
  );
};

export const getElemDimensions = (elem: HTMLElement | null): AxisBox2D => {
  const elemRect = elem?.getBoundingClientRect();

  const offset = !isSSR ? window.scrollY : 0;
  return {
    x: {
      min: elemRect?.left || 0,
      max: (elemRect?.left || 0) + (elemRect?.width || 0),
    },
    y: {
      min: (elemRect?.top || 0) + offset,
      max: (elemRect?.top || 0) + (elemRect?.height || 0) + offset,
    },
  };
};


export const scrollToMainContent = (wait: number | boolean = 0): void => {
  const mainContentOffset = document.getElementById("gm-page-content")
    ?.offsetTop;
  if (
    !isSSR &&
    mainContentOffset &&
    document.documentElement.scrollTop !== mainContentOffset
  ) {
    setTimeout(() => {
      scroll.scrollTo(mainContentOffset, {
        duration: 300,
        smooth: true,
      });
    }, (typeof wait === 'boolean') ? 400 : wait);
  }
};

export const scrollToTop = (wait: number | boolean = 0): void => {
  setTimeout(() => {
    scroll.scrollTo(0, {
      duration: 300,
      smooth: true,
    });
  }, (typeof wait === 'boolean') ? 400 : wait);
}

export const setMessage = (
  message: TMessage,
  setter: SetterOrUpdater<TMessage | null>,
  willNavigate: boolean = true
) => {
  setTimeout(
    () => {
      setter(message);
    },
    willNavigate ? 200 : 0
  );

  scrollToMainContent(true);
};

export const getPreparedMessages = () => cardsData;

export const getRandomPreparedMessage = (): TMessage => {
  const all = getPreparedMessages();
  return all[Math.floor(Math.random() * all.length)];
};
export const getRandomPreparedMessages = (amount: number): TMessage[] => {
  let messages: TMessage[] = [];
  let message: TMessage;
  do {
    message = getRandomPreparedMessage();
    if( !find( messages, (m) => { 
      return isEqual(message.words, m.words) || 
        // different colors (also avoid green+dark_green)
        (amount <= 3 && (message.background === m.background || 
          (message.background.indexOf('green') !== -1) && m.background.indexOf('green') !== -1)) || 
        // different mascots
        (message.mascot === m.mascot && amount <= 6)
    } ) ) {
      messages.push(message);
    }
  } while ( messages.length < amount )

  return messages;
};

export const changeWordsOrder = (wordsList: Array<any>) => {
  const newOrder: any = [];
    
    if (wordsList.length > 5) {
      const firstFive = wordsList.slice(0, 5);
      const restWords = wordsList.slice(5, wordsList.length);

      newOrder.push(...restWords);
      newOrder.push(...firstFive);
    } else {
      newOrder.push(...wordsList);
    }
    
    return [...newOrder].reverse();
}

export const dataLayerShare = (event: string) => {
  if (!window.dataLayer) return;

  window.dataLayer.push({
    'event': event
  })
};