import { useContext } from "react";
import localeContext from "~/localeContext";

export const paths = {
  home: '/',
  animaton: '/creating',
  customize: '/customize',
  card: '/card',
  basic: ''
}

export const getPath = ( key: keyof typeof paths ) => {
  return `${paths[key] || ('/'+key)}`;
}