import { TWord } from "~/content/types";

export const createWord = ({ 
    text, 
    key = 0, 
    isDragged = false, 
    isSelected = false,
    isDraggedOverArea = false,
    index = -1
  }: TWord): TWord => {
  
    return {
      text,
      key,
      isDragged,
      isSelected,
      isDraggedOverArea,
      index
    }
}
