import { css } from '@emotion/core'

export const keyframes = css`
  @keyframes keyframesOrbitSmall {
    from { 	transform: rotate(0deg) translateX(12px) rotate(0deg); }
    to   {  transform: rotate(360deg) translateX(12px) rotate(-360deg); }
  }
  @keyframes keyframesOrbitMedium {
    from { 	transform: rotate(0deg) translateX(7px) rotate(0deg); }
    to   {  transform: rotate(-360deg) translateX(7px) rotate(360deg); }
  }
  @keyframes keyframesOrbitLarge {
    from { 	transform: rotate(0deg) translateX(5px) rotate(0deg); }
    to   {  transform: rotate(360deg) translateX(5px) rotate(-360deg); }
  }

  @keyframes keyframesSelfRotate {
    from { transform: rotate(0deg) }
    to { transform: rotate(-7deg) }
  }
  @keyframes keyframesSelfRotate90 {
    from { transform: rotate(30deg) }
    to { transform: rotate(-30deg) }
  }
`
