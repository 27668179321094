// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-templates-404-tsx": () => import("./../../src/templates/404.tsx" /* webpackChunkName: "component---src-templates-404-tsx" */),
  "component---src-templates-basic-page-tsx": () => import("./../../src/templates/basicPage.tsx" /* webpackChunkName: "component---src-templates-basic-page-tsx" */),
  "component---src-templates-card-tsx": () => import("./../../src/templates/card.tsx" /* webpackChunkName: "component---src-templates-card-tsx" */),
  "component---src-templates-creating-tsx": () => import("./../../src/templates/creating.tsx" /* webpackChunkName: "component---src-templates-creating-tsx" */),
  "component---src-templates-customize-tsx": () => import("./../../src/templates/customize.tsx" /* webpackChunkName: "component---src-templates-customize-tsx" */),
  "component---src-templates-index-tsx": () => import("./../../src/templates/index.tsx" /* webpackChunkName: "component---src-templates-index-tsx" */),
  "component---src-templates-prizes-tsx": () => import("./../../src/templates/prizes.tsx" /* webpackChunkName: "component---src-templates-prizes-tsx" */)
}

