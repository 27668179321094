export const colors = {
  black: '#141414',
  white: '#ffffff',
  bg: '#43ab44',
  text: '#ffffff',
  green: '#097d37',
  darkgreen: '#04461e',
  darkpalegreen: '#097d37',
  lightgreen: '#a1f1a1',
  darkgrey: '#373736',
  orange: '#ed672b',
  red: '#c24202'
};

export const fonts = {
  default: 'Heebo, sans-serif',
  headers: 'Heebo, sans-serif',
  headersLight: 'Heebo, sans-serif',
  pills: 'Heebo, sans-serif'
};

export const breakpoints = {
  xs: 360,
  sm: 460,
  md: 768,
  lg: 1024,
  xl: 1200,
  xxl: 1400,
};

export const widths = {
  md: 720,
  lg: 960,
  xl: 1280,
};

export const border = {
  radius: {
    regular: 8,
  }
};

export const dimensions = {
  fontSize: {
    large: 18,
    regular: 16,
    small: 14,
    smaller: 12,
    tiny: 10,
  },
  lineHeight: {
    regular: 1.5,
    heading: 1.15,
  },

  containerPadding: 32,
};

export const animation = {
  fast: 200,
  slow: 350,
  subtle: 500,
};

